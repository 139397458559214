<template>
  <div id="withdraw">
    <div class="inDev">
      <img src="@/assets/image/PersonalCenter/FinancialCenter/inDev.png" alt="">
      <div class="inDev_tip">该功能正在开发中，敬请期待！</div>
    </div>
<!--    <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="100px" class="demo-ruleForm">-->
<!--      <el-form-item label="账户余额："  prop="account_money">-->
<!--        <div class="account_money"><span>¥</span>{{ infoForm.account_money}}</div>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="冻结余额："  prop="frozenAmount" v-if="frozenAmount != 0">-->
<!--        <div class="account_money"><span>¥</span>{{frozenAmount}}</div>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="提现金额："  prop="withdraw_money">-->
<!--        <el-input placeholder="请输入提现金额" v-model="infoForm.withdraw_money" ></el-input><span id="Yuan">元</span>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="提现方式：" class="withdraw_method" prop="withdraw_method">-->
<!--        <div class="withdraw_method_ground">-->
<!--          <div v-if="Alipay !== ''" :class="['withdraw_method_item','Alipay',withdraw_money_method === 2?'method_item_acitve':'']" @click="clickMethodItem(2)">-->
<!--            <div id="alipay_logo">-->
<!--              <img src="@/assets/image/PersonalCenter/FinancialCenter/AlipayLogo.png" alt="">-->
<!--            </div>-->
<!--            <div class="method_text">支付宝支付</div>-->
<!--            <div class="isAcitve">-->
<!--              <img src="@/assets/image/PersonalCenter/FinancialCenter/AlipayActive.png" alt="">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="Wechat !== ''" :class="['withdraw_method_item','WeChat',withdraw_money_method === 3?'method_item_acitve':'']" @click="clickMethodItem(3)">-->
<!--            <div id="weChat_logo">-->
<!--              <img src="@/assets/image/PersonalCenter/FinancialCenter/WeChatLogo.png" alt="">-->
<!--            </div>-->
<!--            <div class="method_text">微信支付</div>-->
<!--            <div class="isAcitve">-->
<!--              <img src="@/assets/image/PersonalCenter/FinancialCenter/WeChatActive.png" alt="">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="BankCard !== ''" :class="['withdraw_method_item','Bank',withdraw_money_method === 1?'method_item_acitve':'']" @click="clickMethodItem(1)">-->
<!--            <div id="Bank_logo">-->
<!--              <img src="@/assets/image/PersonalCenter/FinancialCenter/BankLogo.png" alt="">-->
<!--            </div>-->
<!--            <div class="method_text">银行卡支付</div>-->
<!--            <div class="isAcitve">-->
<!--              <img src="@/assets/image/PersonalCenter/FinancialCenter/BankActive.png" alt="">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="支付密码："  prop="payment_pwd"  class="payment_pwd">-->
<!--        <el-input placeholder="请输入支付密码" v-model="infoForm.payment_pwd" show-password ></el-input>-->
<!--        <div class="withdraw_money_ground">-->
<!--          <div v-if="!isRNameAuthentication" class="isRNameAuthentication" @click="clickIsPaymentPwd">-->
<!--            注意：您还未实名认证，无法提现！-->
<!--            <span>【点击前往认证】</span>-->
<!--          </div>-->
<!--          <div v-if="!isWithdrawalAccount" class="isWithdrawalAccount" @click="clickIsWithdrawalAccount">-->
<!--            注意：您还未设置提现账户，无法提现！-->
<!--            <span>【点击前往设置】</span>-->
<!--          </div>-->
<!--          <div v-if="!isPaymentPwd" class="isPaymentPwd" @click="clickIsRNameAuthentication">-->
<!--            注意：您还未设置支付密码，无法提现！-->
<!--            <span>【点击前往设置】</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-form-item>-->
<!--    </el-form>-->
<!--    <div class="btn">-->
<!--      <div class="submit_btn" @click="submitForm('infoForm')">立即提现</div>-->
<!--    </div>-->
  </div>
</template>
<script>
import {apiuserInfo,apiUserCashAdd} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      inDev:0,
      //是否实名认证
      isRNameAuthentication:false,
      //是否设置提现账户
      isWithdrawalAccount:false,
      //是否设置支付密码
      isPaymentPwd:false,
      //支付宝 提现账户
      Alipay:'',
      //微信 提现账户
      Wechat:'',
      //银行卡 提现账户
      BankCard:'',
      //提现方式item:active  1 = 银行卡, 2= 支付宝, 3=微信
      withdraw_money_method: 2,
      //冻结余额
      frozenAmount:0,
      //表单信息
      infoForm:{
        account_money:this.$store.state.userData.now_money,
        withdraw_money:'',
        withdraw_method:2,
        payment_pwd:''
      },
      //表单验证
      rules: {
        withdraw_money:[
          { required: true, message: '请输入提现金额', trigger: 'blur' },
          { pattern:/(^[1-9]([0-9]{1,5})?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确的金额'}
        ],
        payment_pwd:[
          { required: true, message: '请输入支付密码', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    //计算开发倒计时天数
    getDevDays(dateString1,dateString2){
      var startDate = dateString1;
      var endDate = Date.parse(dateString2);
      if (startDate>endDate){
        return 0;
      }
      if (startDate==endDate){
        return 1;
      }
      var days=(endDate - startDate)/(1*24*60*60*1000);
      return  parseInt(days);
    },
    /**
     * 提现按钮 表单提交
     * @param formName 表单名称
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid){
          if(!this.isRNameAuthentication || !this.isWithdrawalAccount || !this.isPaymentPwd){
            let msg = ''
            if(!this.isRNameAuthentication){
              msg = '您还未进行实名认证，暂时无法提现！'
            }else if(!this.isWithdrawalAccount){
              msg = '您还未设置提现账户，暂时无法提现！'
            }else if(!this.isPaymentPwd){
              msg = '您还未设置支付密码，暂时无法提现！'
            }
            this.$alert(msg, '注意', {
              confirmButtonText: '前往设置',
              confirmButtonClass:'withdraw_btn',
              customClass: 'withdraw_tips',
              center:true,
              callback: action => {
                if(action==='confirm'){
                  switch (msg) {
                    case '您还未进行实名认证，暂时无法提现！':
                      this.clickIsPaymentPwd()
                      break;
                    case '您还未设置提现账户，暂时无法提现！':
                      this.clickIsWithdrawalAccount()
                      break;
                    case '您还未设置支付密码，暂时无法提现！':
                      this.clickIsRNameAuthentication()
                      break;
                  }
                }
              }
            });
          }else{
            apiUserCashAdd({
              amount:this.infoForm.withdraw_money,
              cash_method:this.infoForm.withdraw_method,
              payment_pwd:this.infoForm.payment_pwd,
            }).then(res=>{
              this.$message({
                type:'success',
                center:true,
                message:"提现成功"
              })
              this.getBasicInfo()
              this.infoForm.withdraw_money = ''
              this.infoForm.withdraw_method = 2,
              this.infoForm.payment_pwd = ''
            }).catch(err=>{
              this.$message({
                type:'error',
                center:true,
                message:err.msg
              })
            })
          }
        }else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    //提现方式 选项卡点击事件
    clickMethodItem(item){
      this.infoForm.withdraw_method = item
      this.withdraw_money_method = item
    },
    //点击前往实名认证
    clickIsPaymentPwd(){
      this.$router.push({
        path:'/personalCenter/personalInfo/rNameAuthentication'
      })
    },
    //点击设置提现账户
    clickIsWithdrawalAccount(){
      this.$router.push({
        path:'/personalCenter/personalInfo/withdrawalAccount'
      })
    },
    //点击设置支付密码
    clickIsRNameAuthentication(){
      this.$router.push({
        path:'/personalCenter/personalInfo/paymentPwd'
      })
    },
    /**
     * 获取 提现账户 信息
     */
    getBasicInfo(){
      apiuserInfo({
        action:'cash'
      }).then(res=>{
        this.isRNameAuthentication = res.data.auth_state
        this.isWithdrawalAccount = res.data.has_acc
        this.isPaymentPwd = res.data.has_paypwd
        this.infoForm.account_money = res.data.freeAmount
        this.frozenAmount = res.data.frozenAmount
        this.Alipay = res.data.alipay
        this.Wechat = res.data.wechat
        this.BankCard = res.data.bankcard
      })
    }
  },
  mounted () {
    this.getBasicInfo()
  },
  watch: {
  },
  computed: {

  }
}
</script>

<style lang="less">
.withdraw_tips{
  width: 386px;
  height: 232px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 0;
  .el-message-box__header{
    padding: 38px 0 0 0;
    .el-message-box__title{
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #5B5B5B;
      line-height: 28px;
    }
  }
  .el-message-box__content{
    padding: 30px 0 0 0;
    .el-message-box__message{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      line-height: 22px;
    }
  }
  .el-message-box__btns{
    padding: 36px 0 0 0 ;
    button{
      width: 200px;
      height: 40px;
      border: none;
      background: #FFDB51;
      border-radius: 28px;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #000000;
      text-align: center;
    }
  }
}
</style>

<style lang='less' scoped>
#withdraw{
  //padding: 30px 77px 0;
  //display: flex;
  //flex-direction: column;
  //align-items: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  justify-content: center;
  .inDev_tip{
    font-size: 14px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #9A9A9A;
    line-height: 20px;
    span{
      color: #FCAD13;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
  }
  /deep/.el-input{
    width: 250px;
  }
  /deep/.el-input__inner{
    width: 250px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #8F8F8F;
  }
  /deep/.is-error .el-input__inner{
    border-color: #F56C6C;
  }
  /deep/.el-form-item__label{
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    padding: 0 0 0 12px;
  }
  /deep/.is-required .el-form-item__label{
    padding: 0;
  }
  /deep/.el-form-item__content{
    margin-left: 0 !important;
  }
  /deep/.el-form-item__error{
    //position: relative;
    margin-left: 100px;
  }
  //账户余额
  .account_money{
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #FCAD13;
    line-height: 40px;
    span{
      font-size: 20px;
      line-height: 28px;
    }
  }
  //提现金额 单位：元
  #Yuan{
    margin-left: 10px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }

  //支付密码
  .payment_pwd{
    /deep/.el-form-item__error{
      top: 45px;
    }
    //未认证
    .withdraw_money_ground{
      margin: 29px 0 0 12px;
      display: flex;
      flex-direction: column;
      div{
        width: 446px;
        height: 36px;
        background: #FFF0F0;
        padding: 0 64px 0 30px;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #5B5B5B;
        margin:0 0 6px 0;
        span{
          cursor: pointer;
          float: right;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #FF750C;

        }
      }
      .isRNameAuthentication{

      }
      .isWithdrawalAccount{

      }
      .isPaymentPwd{

      }
    }
  }

  //提现方式 选项卡
  .withdraw_method{
    display: flex;
    flex-direction: column;
    .withdraw_method_ground{
      display: flex;
      flex-direction: row;
      margin: 0 0 0 12px;
      .withdraw_method_item{
        cursor: pointer;
        width: 189px;
        height: 80px;
        border-radius: 10px;
        margin: 0 10px 0 0 ;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        .method_text{
          margin:  0 0 0 10px;
          font-size: 14px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
          line-height: 20px;
        }
        .isAcitve{
          position: absolute;
          top: -1px;
          left: 149px;
          width: 41px;
          height: 41px;
          border-radius: 10px;
          display: none;
          img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
        }
      }
      .Alipay{
        border: 1px solid #06B4FD;
        #alipay_logo{
          width: 34px;
          height: 35px;
          border-radius: 18px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .WeChat{
        border: 1px solid #07C160;
        #weChat_logo{
          width: 34px;
          height: 35px;
          border-radius: 18px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .Bank{
        border: 1px solid #FCAE36;
        #Bank_logo{
          width: 34px;
          height: 35px;
          border-radius: 18px;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .method_item_acitve{
        .isAcitve{
          display: block;
        }
      }
    }
  }

  //提现按钮
  .btn{
    margin: 0 0 0 12px;
    display: flex;
    flex-direction: column;
    .submit_btn{
      width: 200px;
      height: 40px;
      line-height: 40px;
      background: #FFDB51;
      border-radius: 28px;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #000000;
      text-align: center;
      cursor: pointer;
    }
  }


}
</style>
